<template>
  <div class="container p-2">
    <div class="bg-white radius-5 p-2 px-4 my-2" v-for="(list,index) in data" :key="index">
      <div class="row border-bottom">
        <div class="col-1 p-0 py-1">
          <img :src="list.conmpany.logo" height="30" width="30"/>
        </div>

        <div class="col-8 p-0 px-1 pt-1 text-truncate align-middle">
          {{ list.name }}
        </div>

        <div class="col-3 p-0 text-r pr-1 pt-1">
          <a class="text-muted" :href="`/tool/inFactory/info?job_id=${list.id}&title=${list.name}`">查看明细</a>
          <i class="fa-solid fa-angle-right text-muted"></i>
        </div>
      </div>
      <div class="fs-8 py-1">
        <span class="text-muted">{{ list.nums }} 人</span>

      </div> 
    </div>

  </div>
 
</template>
<script setup>
import { ref } from 'vue'

const s = 1
const data = ref([])

//测试数据
const a = 5
for(var i=0; i<a; i++) {
  data.value.push(
    {
      id: 100 + i, //职位Id
      name: '人事主管', //职位名称
      nums: 2, //驻厂人数
      conmpany: {
        id: 1001, //企业Id
        name: '97打工网', //企业名称
        logo: 'https://picsum.photos/60/60' //企业logo
      }
      
    }
  )
}



</script>

<style>
</style>